html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0px;
}

body {
  background-color: #f2f2f2;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito', sans-serif;
  height: 100vh;
}

#root.dark {
  background-color: #1c1c1c;
}


header {
    margin-top: 0px;
    padding-left: 20px;
    border-radius: 14px;
    background: var(--main-background-color);
    position:-webkit-sticky;
    position:sticky;
    max-width: 1200px;
    width: 95vw;
    height: 100px;
    top: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 11px;
    background: #f2f2f2;
    box-shadow:  15px 15px 31px #cecece,
             -15px -15px 31px #ffffff;
             
    text-shadow: 1px 1px 5px rgb(255, 255, 204);
}

header h1{
    color:  var(--main-font-color);
    text-shadow: 1px 1px 5px rgb(255, 255, 204);
}

@media (max-width:1000px) {

    header{
        height: 90px;
    }

    header h1{
        font-size: 1.5em;
    }

    header h2 {
        font-size: 1.2em;
        padding-bottom: 15px;
    }
}
.screen {
    width: 75%;
    margin-top: 20px;
    height: calc(100% - 20px);
    background-color: white;
    border-radius: 11px;
    background: #f2f2f2;
    box-shadow: inset 5px 5px 10px #dfdfdf,
        inset -5px -5px 10px #ffffff;
    float: left;
    overflow-y: scroll;
    
}

@media (max-width: 1000px) {
    .screen {
        float: none;
        width: 100%;
        height: 100%;
        margin-top: 0px;
    }
}

.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow-x: hidden;
}

.about article{
    width: 100%;
}

.about_heading{
    grid-column: 1/3;
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 1.4em;
    font-weight: 800;
    box-sizing: border-box;
    margin: 30px 0;
    padding: 20px 0;
    background: linear-gradient(
        rgb(0, 0, 0),
        rgb(255, 0, 221)
      ) fixed;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}

.about_heading::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    mix-blend-mode:color-burn;
    background: radial-gradient(circle, rgb(175, 175, 175) 35%, rgba(242,242,242,1) 50%);
}


.about_general{
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
}

.about_general p{
    display: block;
    padding: 0 40px;
    font-size: larger;
}

.about_general p:first-of-type{
    margin-bottom: 30px;
}

.about_general span{
    font-weight: 800;
}

.about_general a{
    text-decoration:underline;
    color: black;
}

.about_general img{
    grid-area: 2/2/3/3;
    width: 300px;
    float: left;
    margin: 0 40px;
    border-radius: 10px;
}

.about_github{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.about_github img:nth-of-type(1){
    width: 80%;
}

.about_github img:nth-of-type(2), .about_github img:nth-of-type(3) {
    width: calc(40% - 5px);
}


.about_skills_container {
    width: 100%;
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 35px 25px;
    gap: 35px 25px;
}

.about_skills_container>div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.about_skills_container>div>p{
    font-weight: bolder;
}

.about_skills_container img {
    width: 90px;
    height: 90px;
}

.about_hobbies_container {
    width: 100%;
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 25px;
    gap: 25px;
}

.about_hobbies_container img {
    width: 80px;
    height: 80px;
    margin: 30px 0 80px;
}

.about_education p{
    padding: 0 40px;
    text-align: center;
}

.about_education_university {
    margin-top: 20px;
    font-weight: 800;
    font-size: larger;
}

.about_education_specialty{
    font-weight: 600;
}

#codewars_badge {
    margin-top: 20px;
}

@media (max-width: 1000px) {
    .about_hobbies_container img {
        width: 80px;
        height: 80px;
        margin: 0px
    }
}

@media (max-width: 700px) {
    .about_general{
        grid-template-columns: 1fr;
        text-align: center;
    }

    .about_general img{
        grid-area:2/1/3/2;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .about_heading{
        grid-column: 1/2;
    }

    #codewars_link {
        display: flex;
        align-items: center;
    }
}

.portfolio_container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
    height: auto;
    padding-bottom: 100px;
}

.portfolio_category_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    margin: 40px auto 20px;
    justify-content: space-around;
}

.portfolio_menu_item summary {
    text-align: center;
    padding-bottom: 10px;
    padding: 20px;
    border-radius: 9px;
    background: #f2f2f2;
    box-shadow: 19px 19px 37px #cecece,
        -19px -19px 37px #ffffff;
    font-size: larger;
    cursor: pointer;
}

.portfolio_submenu_item{
    text-align: center;
    width: 100%;
    text-indent:0;
    margin-top: 30px;
    font-weight: lighter;
}

.portfolio_fm_explanation {
    margin: 30px;
    text-align: center;
}

.portfolio_fm_explanation a{
    font-weight: bolder;
    cursor: pointer;
    text-decoration: none;
    color: black;
    
}

.portfolio_fm_explanation a:hover{
    text-decoration:underline;
    -webkit-transform: scale(2);
            transform: scale(2);
}
.portfolio_card {
    height: 189px;
    width: 252px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 5px 5px 10px #e1e1e1,
        -5px -5px 10px #ffffff;
    border-radius: 7px;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
}

.portfolio_card img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    border: 5px double #f2f2f2;
    border-radius: 5px;
    transition: .5s;
}   

.portfolio_card a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    cursor: pointer;
    color: black;
    text-align: center;
}

.portfolio_card:hover {
    transition: -webkit-transform 0.7s;
    transition: transform 0.7s;
    transition: transform 0.7s, -webkit-transform 0.7s;
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
}

.portfolio_card .portfolio_card_details {
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    padding: 7px;
    top: 0px;
    width: 252px;
    height: 189px;
    backdrop-filter: blur(8px) saturate(94%);
    -webkit-backdrop-filter: blur(8px) saturate(94%);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    transition: -webkit-transform 0.7s;
    transition: transform 0.7s;
    transition: transform 0.7s, -webkit-transform 0.7s;
    -webkit-transform: rotateX(+90deg);
            transform: rotateX(+90deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
}
.portfolio_card:hover .portfolio_card_details {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
}


.portfolio_card_details_name {
    font-size: larger;
    word-break:normal;
}
.portfolio_card_details_langs {
    font-size: smaller;
}
.portfolio_card_details_click {
    font-size: 12px;
}


.work {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 30px;
    grid-gap: 20px;
    gap: 20px;
}

.work_intro{
    padding: 40px;
    text-indent: 30px;
    font-size: larger;
    text-align: justify;
}

.work article{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-bottom: 1px solid #e3e3e3;
}

.work article.webdev {
    background: url(/static/media/webdev.fd1d5775.jpeg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40%;
}

.work article.realtor {
    background: url(/static/media/realtor.23a31e76.jpeg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40%;
}

.work article.elections {
    background: url(/static/media/elections.5cb4175f.jpg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40%;
}

.work article.tutor {
    background: url(/static/media/tutor.e43131c3.jpg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40%;
}

.work article.x5 {
    background: url(/static/media/pyaterochka.1aa0d557.webp);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40% ;
}
.work article.incity {
    background: url(/static/media/incity.05940580.jpg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40% ;
}

.work article.mts {
    background: url(/static/media/mts.f4a50665.jpg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40% ;
}

.work article.physics {
    background: url(/static/media/physics.c6f818f3.jpg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40% ;
}

.work li {
    width: calc(50%);
}

.work ul {
    margin-bottom: 20px;
}

.work__heading {
    text-align: left;
    font-size: 24px;
    font-weight: 800;
    margin: 0px 0px 7px; 
}

.work__heading+p {
    margin-bottom: 10px;
}

.work__heading+p>span {
    font-weight: bolder;
}

@media (max-width: 600px) {
    
.work article.work__item {
background: none;
}

.work li {
    width: calc(100%);
}
}
main {
    max-width: 1200px;
    width: 95vw;
    height: calc(100vh - 150px);
}

@media (max-width: 1000px) {
    main {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
    }
}
aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

aside form {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-top: 40px;
}

aside label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px #dddddd solid;
    background: #f2f2f2;
    box-shadow: 20px 20px 60px #cecece,
        -20px -20px 60px #ffffff;
    cursor: pointer;
}

aside label:hover {
    background: linear-gradient(145deg, #dadada, #ffffff);
    box-shadow: 20px 20px 60px #cecece,
        -20px -20px 60px #ffffff;
}

aside input {
    visibility: hidden;

}



.links {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 200px;
    padding: 30px 0px 20px 0px;
    z-index: 1;
    flex-wrap: wrap;
}

.links a {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 88px;
    background: linear-gradient(145deg, #ffffff, #dadada);
    box-shadow: 8px 8px 16px #cecece,
        -8px -8px 16px #ffffff;
    padding: 15px;
    cursor: pointer;
    z-index: 4;
        color: black;
}

.links a:hover {
    background: linear-gradient(145deg, #dadada, #ffffff);
    box-shadow: 8px 8px 16px #cecece,
        -8px -8px 16px #ffffff;

}

@media (max-width: 1000px) {

    aside {
        flex-direction: column-reverse;
        margin-top: -47px;
        width: 90vw;
    }

    aside form {
        padding-top: 0px;
        padding-left: 0;
        flex-direction: row;
        height: auto;
        width: 100%;

        justify-content: center;
    }

    aside label {
        width: auto;
        height: 40px;
        padding: 10px;
        position: relative;
        top: -20px;
        margin-bottom: -10px;
    }

    .links {
        padding: 0px 7px 20px 0px;
        justify-content: flex-start;
        width: 100%;
    }

    .links a {
        width: 35px;
        height: 35px;
        padding: 9px;
    }
}
