aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

aside form {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-top: 40px;
}

aside label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px #dddddd solid;
    background: #f2f2f2;
    box-shadow: 20px 20px 60px #cecece,
        -20px -20px 60px #ffffff;
    cursor: pointer;
}

aside label:hover {
    background: linear-gradient(145deg, #dadada, #ffffff);
    box-shadow: 20px 20px 60px #cecece,
        -20px -20px 60px #ffffff;
}

aside input {
    visibility: hidden;

}



.links {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 200px;
    padding: 30px 0px 20px 0px;
    z-index: 1;
    flex-wrap: wrap;
}

.links a {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 88px;
    background: linear-gradient(145deg, #ffffff, #dadada);
    box-shadow: 8px 8px 16px #cecece,
        -8px -8px 16px #ffffff;
    padding: 15px;
    cursor: pointer;
    z-index: 4;
        color: black;
}

.links a:hover {
    background: linear-gradient(145deg, #dadada, #ffffff);
    box-shadow: 8px 8px 16px #cecece,
        -8px -8px 16px #ffffff;

}

@media (max-width: 1000px) {

    aside {
        flex-direction: column-reverse;
        margin-top: -47px;
        width: 90vw;
    }

    aside form {
        padding-top: 0px;
        padding-left: 0;
        flex-direction: row;
        height: auto;
        width: 100%;

        justify-content: center;
    }

    aside label {
        width: auto;
        height: 40px;
        padding: 10px;
        position: relative;
        top: -20px;
        margin-bottom: -10px;
    }

    .links {
        padding: 0px 7px 20px 0px;
        justify-content: flex-start;
        width: 100%;
    }

    .links a {
        width: 35px;
        height: 35px;
        padding: 9px;
    }
}