.portfolio_container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
    height: auto;
    padding-bottom: 100px;
}

.portfolio_category_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin: 40px auto 20px;
    justify-content: space-around;
}

.portfolio_menu_item summary {
    text-align: center;
    padding-bottom: 10px;
    padding: 20px;
    border-radius: 9px;
    background: #f2f2f2;
    box-shadow: 19px 19px 37px #cecece,
        -19px -19px 37px #ffffff;
    font-size: larger;
    cursor: pointer;
}

.portfolio_submenu_item{
    text-align: center;
    width: 100%;
    text-indent:0;
    margin-top: 30px;
    font-weight: lighter;
}

.portfolio_fm_explanation {
    margin: 30px;
    text-align: center;
}

.portfolio_fm_explanation a{
    font-weight: bolder;
    cursor: pointer;
    text-decoration: none;
    color: black;
    
}

.portfolio_fm_explanation a:hover{
    text-decoration:underline;
    transform: scale(2);
}