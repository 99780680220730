.portfolio_card {
    height: 189px;
    width: 252px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 5px 5px 10px #e1e1e1,
        -5px -5px 10px #ffffff;
    border-radius: 7px;
    transition: transform 1s;
}

.portfolio_card img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    border: 5px double #f2f2f2;
    border-radius: 5px;
    transition: .5s;
}   

.portfolio_card a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    cursor: pointer;
    color: black;
    text-align: center;
}

.portfolio_card:hover {
    transition: transform 0.7s;
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
}

.portfolio_card .portfolio_card_details {
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    padding: 7px;
    top: 0px;
    width: 252px;
    height: 189px;
    backdrop-filter: blur(8px) saturate(94%);
    -webkit-backdrop-filter: blur(8px) saturate(94%);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    transition: transform 0.7s;
    transform: rotateX(+90deg);
    transform-origin: bottom;
}
.portfolio_card:hover .portfolio_card_details {
    transform: rotateX(0deg);
}


.portfolio_card_details_name {
    font-size: larger;
    word-break:normal;
}
.portfolio_card_details_langs {
    font-size: smaller;
}
.portfolio_card_details_click {
    font-size: 12px;
}

