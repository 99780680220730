.screen {
    width: 75%;
    margin-top: 20px;
    height: calc(100% - 20px);
    background-color: white;
    border-radius: 11px;
    background: #f2f2f2;
    box-shadow: inset 5px 5px 10px #dfdfdf,
        inset -5px -5px 10px #ffffff;
    float: left;
    overflow-y: scroll;
    
}

@media (max-width: 1000px) {
    .screen {
        float: none;
        width: 100%;
        height: 100%;
        margin-top: 0px;
    }
}
