html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0px;
}

body {
  background-color: #f2f2f2;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito', sans-serif;
  height: 100vh;
}

#root.dark {
  background-color: #1c1c1c;
}