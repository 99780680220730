.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow-x: hidden;
}

.about article{
    width: 100%;
}

.about_heading{
    grid-column: 1/3;
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 1.4em;
    font-weight: 800;
    box-sizing: border-box;
    margin: 30px 0;
    padding: 20px 0;
    background: linear-gradient(
        rgb(0, 0, 0),
        rgb(255, 0, 221)
      ) fixed;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}

.about_heading::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    mix-blend-mode:color-burn;
    background: radial-gradient(circle, rgb(175, 175, 175) 35%, rgba(242,242,242,1) 50%);
}


.about_general{
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
}

.about_general p{
    display: block;
    padding: 0 40px;
    font-size: larger;
}

.about_general p:first-of-type{
    margin-bottom: 30px;
}

.about_general span{
    font-weight: 800;
}

.about_general a{
    text-decoration:underline;
    color: black;
}

.about_general img{
    grid-area: 2/2/3/3;
    width: 300px;
    float: left;
    margin: 0 40px;
    border-radius: 10px;
}

.about_github{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.about_github img:nth-of-type(1){
    width: 80%;
}

.about_github img:nth-of-type(2), .about_github img:nth-of-type(3) {
    width: calc(40% - 5px);
}


.about_skills_container {
    width: 100%;
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 35px 25px;
}

.about_skills_container>div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.about_skills_container>div>p{
    font-weight: bolder;
}

.about_skills_container img {
    width: 90px;
    height: 90px;
}

.about_hobbies_container {
    width: 100%;
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.about_hobbies_container img {
    width: 80px;
    height: 80px;
    margin: 30px 0 80px;
}

.about_education p{
    padding: 0 40px;
    text-align: center;
}

.about_education_university {
    margin-top: 20px;
    font-weight: 800;
    font-size: larger;
}

.about_education_specialty{
    font-weight: 600;
}

#codewars_badge {
    margin-top: 20px;
}

@media (max-width: 1000px) {
    .about_hobbies_container img {
        width: 80px;
        height: 80px;
        margin: 0px
    }
}

@media (max-width: 700px) {
    .about_general{
        grid-template-columns: 1fr;
        text-align: center;
    }

    .about_general img{
        grid-area:2/1/3/2;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .about_heading{
        grid-column: 1/2;
    }

    #codewars_link {
        display: flex;
        align-items: center;
    }
}
