header {
    margin-top: 0px;
    padding-left: 20px;
    border-radius: 14px;
    background: var(--main-background-color);
    position:sticky;
    max-width: 1200px;
    width: 95vw;
    height: 100px;
    top: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 11px;
    background: #f2f2f2;
    box-shadow:  15px 15px 31px #cecece,
             -15px -15px 31px #ffffff;
             
    text-shadow: 1px 1px 5px rgb(255, 255, 204);
}

header h1{
    color:  var(--main-font-color);
    text-shadow: 1px 1px 5px rgb(255, 255, 204);
}

@media (max-width:1000px) {

    header{
        height: 90px;
    }

    header h1{
        font-size: 1.5em;
    }

    header h2 {
        font-size: 1.2em;
        padding-bottom: 15px;
    }
}