main {
    max-width: 1200px;
    width: 95vw;
    height: calc(100vh - 150px);
}

@media (max-width: 1000px) {
    main {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
    }
}